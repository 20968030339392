<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('sourceentity.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('sourceentity.detail.code') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.code }}</vl-properties-data>
    <vl-properties-label>{{ $t('sourceentity.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('sourceentity.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.description }}</vl-properties-data>
    <vl-properties-label>{{ $t('sourceentity.detail.model') }}</vl-properties-label>
    <vl-properties-data> <EntityModelRouterLink :model="props.detail.model" /> </vl-properties-data>
    <vl-properties-label>{{ $t('sourceentity.detail.crs') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.crs.name }}</vl-properties-data>
    <vl-properties-label>{{ $t('sourceentity.detail.dataConnection') }}</vl-properties-label>
    <vl-properties-data> {{ props.detail.dataConnection?.label ?? '/' }}</vl-properties-data>
    <vl-properties-label>{{ $t('sourceentity.detail.schema') }}</vl-properties-label>
    <vl-properties-data>{{ props.detail.schema ?? '/' }}</vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { SourceEntityDetailDto } from '@/api/portal-api/clients';
import EntityModelRouterLink from '@/modules/configuration/views/entity-model/subcomponents/entity-model-router-link/EntityModelRouterLink.vue';

const props = defineProps<{
  detail: SourceEntityDetailDto;
}>();
</script>
