import { createInstance, dateReviver } from '@/api';
import {
    CreateDeliveryEntityDto,
    CrsDto,
    DeliveryEntityClient,
    DeliveryEntityDetailDto,
    FormatTypeDto,
    UpdateDeliveryEntityDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useDeliveryProcessDefinitionEditStore } from '../delivery-process-definition/delivery-process-definition-edit.store';

export interface DeliveryEntityStoreState {
    items?: { key: string; value: DeliveryEntityDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
    formatTypes: FormatTypeDto[];
    sridTypes: CrsDto[];
}

export const client = new DeliveryEntityClient('', createInstance(), dateReviver);

export const useDeliveryEntityStore = defineStore('delivery-entity-store', {
    state: (): DeliveryEntityStoreState => ({
        items: [],
        loading: false,
        reloading: false,
        formatTypes: [
            { id: 1, code: 'SHP', name: 'Shapefile' },
            { id: 2, code: 'GML', name: 'Geograhpy Markup Language' },
            { id: 3, code: 'KML', name: 'Keyhole Markup Language' },
            { id: 4, code: 'DXF', name: 'Drawing interchange forma' },
            { id: 5, code: 'CSV', name: 'Comma-separated values' },
            { id: 6, code: 'DBF', name: 'DBase File' },
        ],
        sridTypes: [
            { id: 4258, name: 'Etrs89' },
            { id: 4326, name: 'Wgs84' },
            { id: 31370, name: 'Lambert72' },
            { id: 3857, name: 'WebMercator' },
            { id: 3812, name: 'Lambert2008' },
        ],
    }),
    getters: {
        byDefinition: (state) => {
            return (definitionId: number): DeliveryEntityDetailDto[] => {
                const key = `definition-${definitionId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
        bySpecification: (state) => {
            return (specificationId: number): DeliveryEntityDetailDto[] => {
                const key = `specification-${specificationId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
    },
    actions: {
        async getByDefinition(definitionId: number, reloading: boolean = false) {
            const key = `definition-${definitionId}`;
            reloading ? (this.reloading = true) : (this.loading = true);
            try {
                const response = await client.getByDefinition(definitionId);
                const items = this.items.filter((x) => x.key !== key);
                if (response?.length) {
                    this.items = [...items, { key, value: response }];
                } else {
                    this.items = [...items];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryentity.detail.error.get.title'),
                    i18n.global.t('deliveryentity.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async getBySpecification(specificationId: number, reloading: boolean = false) {
            const key = `specification-${specificationId}`;
            reloading ? (this.reloading = true) : (this.loading = true);
            try {
                const response = await client.getBySpecification(specificationId);
                const items = this.items.filter((x) => x.key !== key);
                if (response?.length) {
                    this.items = [...items, { key, value: response }];
                } else {
                    this.items = [...items];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryentity.detail.error.get.title'),
                    i18n.global.t('deliveryentity.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(specificationId: number, dto: CreateDeliveryEntityDto) {
            this.reloading = true;

            try {
                const response = await client.create(dto);

                await this.getBySpecification(specificationId, true);

                return response.id;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryentity.detail.create.error.title'),
                    i18n.global.t('deliveryentity.detail.create.error.content'),
                );
                this.reloading = false;

                return null;
            } finally {
                useDeliveryProcessDefinitionEditStore().closeCreate();
            }
        },
        async update(specificationId: number, id: number, dto: UpdateDeliveryEntityDto) {
            this.reloading = true;

            try {
                await client.update(id, dto);

                useAlertStore().setSuccess(
                    i18n.global.t('deliveryentity.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.getBySpecification(specificationId, true);
            } catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryentity.detail.error.update.title'),
                    i18n.global.t('deliveryentity.detail.error.update.content'),
                );

                this.reloading = false;
            } finally {
                useDeliveryProcessDefinitionEditStore().closeEdit();
            }
        },
        async remove(specificationId: number, id: number) {
            this.reloading = true;
            try {
                await client.delete(id);

                useAlertStore().setSuccess(
                    i18n.global.t('deliveryentity.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                this.getBySpecification(specificationId, true);
            } catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryentity.detail.error.delete.title'),
                    i18n.global.t('deliveryentity.detail.error.delete.content'),
                );
            } finally {
                this.reloading = false;
            }
        },
        clear(specificationId: number) {
            const key = `specification-${specificationId}`;
            this.items = this.items.filter((x) => x.key !== key);
        },
    },
});
