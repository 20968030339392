<template>
  <vl-properties-list>
    <vl-properties-label>{{ $t('productentity.detail.id') }}</vl-properties-label>
    <vl-properties-data>{{ detail.id }}</vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.code') }}</vl-properties-label>
    <vl-properties-data>{{ detail.code }}</vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.label') }}</vl-properties-label>
    <vl-properties-data>{{ detail.label }}</vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.description') }}</vl-properties-label>
    <vl-properties-data>{{ detail.description }}</vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.model') }}</vl-properties-label>
    <vl-properties-data> <EntityModelRouterLink :model="detail.model" /> </vl-properties-data>
    <vl-properties-label>{{ $t('productentity.detail.crs') }}</vl-properties-label>
    <vl-properties-data>{{ detail.crs.name }}</vl-properties-data>
  </vl-properties-list>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ProductEntityDetailDto } from '@/api/portal-api/clients';
import EntityModelRouterLink from '@/modules/configuration/views/entity-model/subcomponents/entity-model-router-link/EntityModelRouterLink.vue';

const props = defineProps<{
  detail: ProductEntityDetailDto;
}>();

const detail = computed((): ProductEntityDetailDto => {
  return props.detail;
});
</script>
