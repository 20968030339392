<template>
  <router-link :to="'/configuration/entitymodel/' + props.model.id" target="_blank">
    [{{ props.model.id }}] {{ props.model.label }} | {{ props.model.contextTypeName }}
  </router-link>
</template>
<script lang="ts" setup>
import { EntityModelLinkDto } from '@/api/portal-api/clients';

const props = defineProps<{
  model: EntityModelLinkDto;
}>();
</script>
