import { createInstance, dateReviver } from '@/api';
import {
    CreateDeliverySpecificationDto,
    DeliverySpecificationClient,
    DeliverySpecificationDetailDto,
    UpdateDeliverySpecificationDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useDeliveryEntityStore } from '../delivery-entity/delivery-entity.store';
import { useDeliveryProcessDefinitionEditStore } from '../delivery-process-definition/delivery-process-definition-edit.store';

export interface DeliverySpecificationStoreState {
    items?: { key: string; value: DeliverySpecificationDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
}

export const client = new DeliverySpecificationClient('', createInstance(), dateReviver);

export const useDeliverySpecificationStore = defineStore('delivery-specification-store', {
    state: (): DeliverySpecificationStoreState => ({
        items: [],
        loading: false,
        reloading: false,
    }),
    getters: {
        byDefinition: (state) => {
            return (definitionId: number): DeliverySpecificationDetailDto[] => {
                const key = `definition-${definitionId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
    },
    actions: {
        async get(id: number) {
            this.loading = true;

            try {
                const response = await client.getById(id);

                this.items = this.items.map((x) => {
                    if (x.value.some((y) => y.id === id)) {
                        x.value = [...x.value.filter((x) => x.id !== id), response];
                    } else {
                        return x;
                    }
                });
            } catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryspecification.detail.get.error.title'),
                    i18n.global.t('deliveryspecification.detail.get.error.content'),
                );
            } finally {
                this.loading = false;
            }
        },
        async getByDefinition(id: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);
            const key = `definition-${id}`;

            try {
                const response = await client.getByDefinition(id);

                if (response.length) {
                    this.items = [{ key, value: response }];
                } else {
                    this.items = [];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryspecification.detail.get.error.title'),
                    i18n.global.t('deliveryspecification.detail.get.error.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(definitionId: number, dto: CreateDeliverySpecificationDto) {
            this.reloading = true;

            try {
                const response = await client.create(dto);

                await this.getByDefinition(definitionId, true);

                return response.id;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryspecification.detail.create.error.title'),
                    i18n.global.t('deliveryspecification.detail.create.error.content'),
                );
                return null;
            } finally {
                this.reloading = false;
                useDeliveryProcessDefinitionEditStore().closeCreate();
            }
        },
        async update(definitionId: number, id: number, dto: UpdateDeliverySpecificationDto) {
            this.reloading = true;

            try{
                await client.update(id, dto)

                useAlertStore().setSuccess(
                    i18n.global.t('deliveryspecification.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.getByDefinition(definitionId, true);
            }
            catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryspecification.detail.error.update.title'),
                    i18n.global.t('deliveryspecification.detail.error.update.content'),
                );
            }
            finally {
                useDeliveryProcessDefinitionEditStore().closeEdit();
                this.reloading = false
            }
        },
        async remove(definitionId: number, id: number) {
            this.reloading = true;

            try{
                await client.delete(id)

                useAlertStore().setSuccess(
                    i18n.global.t('deliveryspecification.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.getByDefinition(definitionId, true);

            }
            catch {
                useAlertStore().setError(
                    i18n.global.t('deliveryspecification.detail.error.delete.title'),
                    i18n.global.t('deliveryspecification.detail.error.delete.content'),
                );
            }
            finally {
                this.reloading = false;

            }
        },
        clear(id: number) {

            const key = `definition-${id}`;

            const specifications = this.items.find((x) => x.key === key)?.value;

            if (specifications) {
                specifications.forEach((specification) => {
                    useDeliveryEntityStore().clear(specification.id);
                });
            }

            this.items = this.items.filter((x) => x.key !== key);
        },
    },
});
