import { createInstance, dateReviver } from '@/api';
import {
    CreateProcessTriggerDto,
    ProcessTriggerClient,
    ProcessTriggerDetailDto,
    UpdateProcessTriggerDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useProcessTriggerEditStore } from './process-trigger-edit.store';

export interface ProcessTriggerDetailStoreState {
    detail?: ProcessTriggerDetailDto;
    loading: boolean;
    reloading: boolean;
    cronDescription: string;
}

export const client = new ProcessTriggerClient('', createInstance(), dateReviver);

export const useProcessTriggerDetailStore = defineStore('process-trigger-detail-store', {
    state: (): ProcessTriggerDetailStoreState => ({
        detail: null,
        loading: false,
        reloading: false,
        cronDescription: null,
    }),
    getters: {},
    actions: {
        async get(id: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);
            try {
                this.detail = await client.getDetail(id);
                return this.detail;
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('processtrigger.detail.error.get.title'),
                    i18n.global.t('processtrigger.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async getDescription(cronSchedule: string) {
            if (!cronSchedule) {
                return;
            }
            try {
                this.cronDescription = await client.getDescription(cronSchedule);
                return this.cronDescription;
            } catch (error) {
                console.error('Error getting cron description', error);
                return '';
            }
        },
        async create(dto: CreateProcessTriggerDto): Promise<number> {
            this.loading = true;
            let response = null;
            try {
                response = await client.create(dto);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('processtrigger.create.error.title'),
                    i18n.global.t('processtrigger.create.error.content'),
                );
            } finally {
                useProcessTriggerEditStore().closeEdit();
                this.loading = false;
            }
            return response?.id;
        },
        async update(id: number, dto: UpdateProcessTriggerDto) {
            try {
                this.reloading = true;
                await client.update(id, dto);
                useAlertStore().setSuccess(
                    i18n.global.t('processtrigger.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );

                await this.get(id, true);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('processtrigger.detail.error.update.title'),
                    i18n.global.t('processtrigger.detail.error.update.content'),
                );
            } finally {
                useProcessTriggerEditStore().closeEdit();
                this.reloading = false;
            }
        },
        async delete(id: number) {
            this.reloading = true;
            try {
                await client.delete(id);
                useAlertStore().setSuccess(
                    i18n.global.t('processtrigger.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                return true;
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('processtrigger.detail.error.delete.title'),
                    i18n.global.t('processtrigger.detail.error.delete.content'),
                );
            } finally {
                useProcessTriggerEditStore().closeEdit();
                this.reloading = false;
            }
        },
        clear(id: number) {
            this.detail = null;
            this.cronDescription = null;
        },
    },
});
