<template>
  <form @submit="onSubmit">
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="keyAttribute"
        :label="t('productTransformationLookupLink.detail.keyAttribute')"
        :placeholder="$t('productTransformationLookupLink.detail.keyAttribute')"
        :options="keyAttributeOptions"
        :custom-label="attributeLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="valueAttribute"
        :label="t('productTransformationLookupLink.detail.valueAttribute')"
        :placeholder="$t('productTransformationLookupLink.detail.valueAttribute')"
        :options="valueAttributeOptions"
        :custom-label="attributeLabel"
      />
    </vl-form-column>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import {
  EntityAttributeDetailDto,
  ProductTransformationLookupLinkDetailDto,
  SourceEntityDetailDto,
} from '@/api/portal-api/clients';
import { useForm } from 'vee-validate';
import { object } from 'yup';
import { useI18n } from 'vue-i18n';
import { useProductProcessDefinitionEditStore } from '@/modules/configuration/store/product-process-definition/product-process-definition-edit.store';
import { useProductTransformationLookupLinkStore } from '@/modules/configuration/store/product-transformation-lookup-link/product-transformation-lookup-link.store';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';

const { t } = useI18n();

const store = useProductTransformationLookupLinkStore();
const editStore = useProductProcessDefinitionEditStore();

const props = defineProps<{
  sourceEntity: SourceEntityDetailDto;
  link: ProductTransformationLookupLinkDetailDto;
}>();

const validationSchema = object({
  keyAttribute: object()
    .required(t('validations.messages.required', { _field_: t('productTransformationLookupLink.detail.keyAttribute') }))
    .test(
      'keyAttribute',
      t('validations.messages.matchPrerequisites', { _field_: t('entitylink.detail.keyAttribute') }),
      (value, ctx) => {
        return (
          (value as EntityAttributeDetailDto).length === values.valueAttribute.length &&
          (value as EntityAttributeDetailDto).dataType.id === values.valueAttribute.dataType.id
        );
      },
    ),
  valueAttribute: object()
    .required(
      t('validations.messages.required', { _field_: t('productTransformationLookupLink.detail.valueAttribute') }),
    )
    .test(
      'valueAttribute',
      t('validations.messages.matchPrerequisites', { _field_: t('entitylink.detail.valueAttribute') }),
      (value, ctx) => {
        return (
          (value as EntityAttributeDetailDto).length === values.keyAttribute.length &&
          (value as EntityAttributeDetailDto).dataType.id === values.keyAttribute.dataType.id
        );
      },
    ),
});

const form = useForm();

const { resetForm, handleSubmit, values, setFieldValue } = useForm({
  validationSchema,
});

const keyAttributeOptions = computed(() => {
  return props.sourceEntity?.model?.attributes.filter((x) => x.id !== values.valueAttribute?.id) ?? [];
});

const valueAttributeOptions = computed(() => {
  return props.sourceEntity?.model?.attributes.filter((x) => x.id !== values.keyAttribute?.id) ?? [];
});

watch(
  keyAttributeOptions,
  () => {
    if (!keyAttributeOptions.value.find((x) => x.id === values?.keyAttribute?.id)) {
      setFieldValue('keyAttribute', null, false);
    }
  },
  { deep: true },
);

watch(
  valueAttributeOptions,
  () => {
    if (!valueAttributeOptions.value.find((x) => x.id === values?.valueAttribute?.id)) {
      setFieldValue('valueAttribute', null, false);
    }
  },
  { deep: true },
);

onMounted(async () => {
  resetForm({
    values: {
      keyAttribute: props.link.keyAttribute,
      valueAttribute: props.link.valueAttribute,
    },
  });
});

const onSuccess = async (values) => {
  await store.update(props.sourceEntity.id, props.link.id, values.keyAttribute?.id, values.valueAttribute?.id);
  editStore.closeEdit();
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const attributeLabel = (type: EntityAttributeDetailDto) => {
  return `${type.code} (${type.label})`;
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
