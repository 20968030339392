<template>
  <vl-layout>
    <vl-grid v-vl-spacer:bottom.medium class="pbs-form-group" mod-stacked-large>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="id">{{ $t('processtrigger.list.filter.id.label') }}</vl-form-message-label>
        <vl-input-field
          id="id"
          v-model="filter.id"
          name="id"
          type="search"
          :placeholder="$t('processtrigger.list.filter.id.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="3" width-s="6" width-xs="12">
        <vl-form-message-label for="triggerType">{{
          $t('processtrigger.list.filter.triggerType.label')
        }}</vl-form-message-label>
        <vl-multiselect
          id="triggerType"
          v-model="filter.triggerType"
          :options="triggerTypes"
          :mod-multiple="false"
          name="triggerType"
          type="triggerType"
          :placeholder="$t('processtrigger.list.filter.triggerType.label')"
          mod-block
          :custom-label="enumerationLabel"
        />
      </vl-column>
      <vl-column width="6" width-s="6" width-xs="12">
        <vl-form-message-label for="description">{{
          $t('processtrigger.list.filter.description.label')
        }}</vl-form-message-label>
        <vl-input-field
          id="description"
          v-model="filter.description"
          name="description"
          type="search"
          :placeholder="$t('processtrigger.list.filter.description.label')"
          mod-block
        />
      </vl-column>
      <vl-column width="6" width-s="6" width-xs="12">
        <vl-form-message-label for="deliveryProcessDefinition">{{
          $t('processtrigger.list.filter.deliveryProcessDefinition.label')
        }}</vl-form-message-label>
        <vl-multiselect
          id="deliveryProcessDefinition"
          v-model="filter.deliveryProcessDefinition"
          :options="deliveryProcessDefinitionOptions"
          :mod-multiple="false"
          name="deliveryProcessDefinition"
          type="deliveryProcessDefinition"
          :placeholder="$t('processtrigger.list.filter.deliveryProcessDefinition.label')"
          mod-block
          :custom-label="(e) => e.label"
          @search-change="onDeliveryProcessDefinitionsSearchChange"
        />
      </vl-column>
      <vl-column width="6" width-s="6" width-xs="12">
        <vl-form-message-label for="productProcessDefinition">{{
          $t('processtrigger.list.filter.productProcessDefinition.label')
        }}</vl-form-message-label>
        <vl-multiselect
          id="productProcessDefinition"
          v-model="filter.productProcessDefinition"
          :options="productProcessDefinitionOptions"
          :mod-multiple="false"
          name="productProcessDefinition"
          type="productProcessDefinition"
          :placeholder="$t('processtrigger.list.filter.productProcessDefinition.label')"
          mod-block
          :custom-label="(e) => e.label"
          @search-change="onProductProcessDefinitionsSearchChange"
        />
      </vl-column>
      <!-- <vl-column width="6" width-s="6" width-xs="12">
        <vl-form-message-label for="organisation">{{
          $t('processtrigger.list.filter.organisation.label')
        }}</vl-form-message-label>
        <vl-multiselect
          id="organisation"
          v-model="filter.organisation"
          :options="organisationOptions"
          :mod-multiple="false"
          name="organisation"
          type="search"
          :placeholder="$t('processtrigger.list.filter.organisation.label')"
          mod-block
          :custom-label="getOrganisationLabel"
          @search-change="onOrganisationSearchChange"
        />
      </vl-column> -->
      <vl-column width="9" width-s="9" width-xs="12">
        <vl-action-group>
          <vl-button :mod-loading="loading" @click="search">{{ $t('processtrigger.list.filter.search') }}</vl-button>
          <vl-button mod-secondary :mod-loading="loading" @click="clear">{{
            $t('processtrigger.list.filter.clear')
          }}</vl-button>
        </vl-action-group>
      </vl-column>
      <vl-column width="3" width-s="3" width-xs="12">
        <vl-action-group mod-align-right>
          <vl-button mod-secondary @click="create">{{ $t('processtrigger.list.filter.create') }}</vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
    <vl-grid>
      <vl-data-table-extended
        v-if="items"
        :data="items"
        :columns="columnMap"
        :fetching="loading"
        :meta="metaData"
        mod-clickable-rows
        mod-zebra
        @column-clicked="columnClicked"
        @pager-clicked="pagerClicked"
      >
        <template v-if="!loading" v-slot:noresults>
          <div v-vl-align:left>
            <div class="vl-u-spacer--large">
              <vl-title tag-name="h2">{{ $t('processtrigger.list.table.noResults.title') }}</vl-title>
              <p>{{ $t('processtrigger.list.table.noResults.subtitle') }}</p>
            </div>
          </div>
        </template>
      </vl-data-table-extended>
    </vl-grid>
  </vl-layout>
</template>
<script lang="ts" setup>
import { Ref, computed, onUnmounted, ref } from 'vue';
import {
  ProcessTriggerListFilter,
  useProcessTriggerListStore,
} from '../../store/process-trigger/process-trigger-list.store';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import {
  DeliveryProcessDefinitionBasicDto,
  EnumerationDto,
  OrganisationDetailDto,
  OrganisationSearchResultDto,
  ProcessTriggerSearchResultDto,
  ProductProcessDefinitionBasicDto,
} from '@/api/portal-api/clients';
import { ColumnDefExtended } from '@/components/vl-data-table-extended/types';
import { RowData } from '@tanstack/vue-table';
import { IAlert, useAlertStore } from '@/modules/core/store/alert.store';
import { useOrganisationStore } from '../../store/organisation/organisation.store';
import { useDeliveryProcessDefinitionListStore } from '../../store/delivery-process-definition/delivery-process-definition-list.store';
import { ENUM_TriggerTypes } from '../../common';
import { useProductProcessDefinitionListStore } from '../../store/product-process-definition/product-process-definition-list.store';

const store = useProcessTriggerListStore();
const organisationStore = useOrganisationStore();
const deliveryProcessDefinitionStore = useDeliveryProcessDefinitionListStore();
const productProcessDefinitionStore = useProductProcessDefinitionListStore();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const organisationOptions: Ref<OrganisationSearchResultDto[]> = ref([]);
const deliveryProcessDefinitionOptions: Ref<DeliveryProcessDefinitionBasicDto[]> = ref([]);
const productProcessDefinitionOptions: Ref<ProductProcessDefinitionBasicDto[]> = ref([]);

const filter = computed((): ProcessTriggerListFilter => {
  return store.filter ?? {};
});

const loading = computed((): boolean => {
  return store.loading;
});

const items = computed((): ProcessTriggerSearchResultDto & { routerLink?: string }[] => {
  return store.items?.map((i) => {
    return {
      ...i,
      routerLink: `/configuration/processtrigger/${i.id}`,
    };
  });
});

const metaData = computed(() => {
  return store.metaData;
});

const columnMap = computed((): ColumnDefExtended<RowData>[] => {
  return [
    {
      accessorKey: 'id',
      header: t('processtrigger.list.table.header.id'),
      enableSorting: true,
      size: 5,
      link: true,
    },
    {
      accessorKey: 'triggerType',
      header: t('processtrigger.list.table.header.triggerType'),
      enableSorting: true,
      size: 10,
      link: true,
      cell: (props) => {
        const triggerType = props?.getValue() as EnumerationDto;
        return triggerType.name;
      },
    },
    {
      accessorKey: 'schedule',
      header: t('processtrigger.list.table.header.schedule'),
      enableSorting: true,
      size: 10,
      link: true,
    },
    {
      accessorKey: 'description',
      header: t('processtrigger.list.table.header.description'),
      enableSorting: true,
      sortDescFirst: false,
      size: 30,
      link: true,
    },
    {
      accessorKey: 'deliveryProcessDefinition',
      header: t('processtrigger.list.table.header.deliveryProcessDefinition'),
      enableSorting: true,
      size: 20,
      link: true,
      cell: (props) => {
        const definition = props?.getValue() as DeliveryProcessDefinitionBasicDto;
        return definition ? definition.label : '-';
      },
    },
    {
      accessorKey: 'productProcessDefinition',
      header: t('processtrigger.list.table.header.productProcessDefinition'),
      enableSorting: true,
      size: 20,
      link: true,
      cell: (props) => {
        const definition = props?.getValue() as ProductProcessDefinitionBasicDto;
        return definition ? definition.label : '-';
      },
    },
  ];
});

const alert = computed((): IAlert => {
  return useAlertStore().alert;
});

const triggerTypes = computed((): EnumerationDto[] => {
  return ENUM_TriggerTypes;
});

const enumerationLabel = (enumerationDto: EnumerationDto) => {
  return `${enumerationDto.name}`;
};

onMounted(() => {
  setTimeout(async () => {
    organisationOptions.value = await organisationStore.getOrganisations();
    deliveryProcessDefinitionOptions.value = await deliveryProcessDefinitionStore.getAll();
    productProcessDefinitionOptions.value = await productProcessDefinitionStore.getAll();
    store.init(createFilterFromRoute());
    await search();
  }, 0);
});

onUnmounted(() => {
  store.clearFilter();
});

const clear = async () => {
  store.clearFilter();
  await search();
};

const search = async () => {
  await store.getProcessTriggers();
  if (!alert.value) {
    updateRoute();
  }
};

const create = () => {
  router.push({ name: 'processtrigger.create' });
};

const createFilterFromRoute = (): ProcessTriggerListFilter => {
  const filter: ProcessTriggerListFilter = {};

  Object.keys(route.query).forEach((k) => {
    if (k === 'organisation') {
      filter[k] = organisationOptions.value.find(
        (x) => x.ovoNumber === encodeURIComponent(route.query[k] as string | number | boolean),
      );
    } else if (k === 'deliveryProcessDefinition') {
      filter[k] = deliveryProcessDefinitionOptions.value.find(
        (x) => x.id === parseInt(encodeURIComponent(route.query[k] as string)),
      );
    } else if (k === 'productProcessDefinition') {
      filter[k] = productProcessDefinitionOptions.value.find(
        (x) => x.id === parseInt(encodeURIComponent(route.query[k] as string)),
      );
    } else {
      filter[k] = encodeURIComponent(route.query[k] as string | number | boolean);
    }
  });

  return filter;
};

const updateRoute = () => {
  const query = {};
  Object.keys(store.filter).forEach((k) => {
    if (k === 'organisation') {
      query[k] = store.filter[k]?.ovoNumber ? encodeURIComponent(store.filter[k].ovoNumber) : '';
    } else if (k === 'triggerType') {
      query[k] = store.filter[k]?.name ? encodeURIComponent(store.filter[k].name) : '';
    } else if (k === 'deliveryProcessDefinition') {
      query[k] = store.filter[k]?.id ? encodeURIComponent(store.filter[k].id) : '';
    } else if (k === 'productProcessDefinition') {
      query[k] = store.filter[k]?.id ? encodeURIComponent(store.filter[k].id) : '';
    } else {
      query[k] = encodeURIComponent(store.filter[k]);
    }
  });

  router.push({
    ...route,
    path: route.path,
    query,
  });
};

const pagerClicked = (pageNumber: number) => {
  store.updatePage(pageNumber);
  search();
};

const columnClicked = (evt: { key: string; direction: string }) => {
  store.updateSorting(evt.key, evt.direction);
  search();
};

const onOrganisationSearchChange = async (query: string) => {
  organisationOptions.value = organisationStore.filter(query);
};

const onDeliveryProcessDefinitionsSearchChange = async (query: string) => {
  deliveryProcessDefinitionOptions.value = deliveryProcessDefinitionStore.search(query);
};

const onProductProcessDefinitionsSearchChange = async (query: string) => {
  productProcessDefinitionOptions.value = productProcessDefinitionStore.search(query);
};

const getOrganisationLabel = (entry: OrganisationSearchResultDto) => {
  return `${entry?.name} (${entry?.ovoNumber})`;
};
</script>
<style lang="scss" scoped>
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colorsRaw.scss';
@import '@govflanders/vl-ui-design-system-style/scss/core/setting/_colors.scss';

.pbs-form-group {
  background: $vl-alt-bg;
  padding: 3rem;
}
.vl-grid {
  flex-grow: 1;
}
</style>
Ref, , ref
