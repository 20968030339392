import { DeliveryTransformationDetailDto, EntityAttributeDetailDto } from '@/api/portal-api/clients';
import { computed, ref } from 'vue';
import { object, string, array, number, mixed } from 'yup';
import { hasSameEntityModel, isAttributeMappingSupported, isEntityMappingType } from './delivery-transformation-utils';

export const useDeliveryTransformationValidations = (t, detail: DeliveryTransformationDetailDto = null) => {
    const attributeMappingValidationSchema = object({
        transformationType: object().required(
            t('validations.messages.required', { _field_: t('deliverytransformation.detail.transformationType') }),
        ),
        fromEntity: object().required(
            t('validations.messages.required', { _field_: t('deliverytransformation.detail.fromEntity') }),
        ),
        toEntity: object().required(
            t('validations.messages.required', { _field_: t('deliverytransformation.detail.toEntity') }),
        ),
        parameter: string().nullable(),
        attributeMappings: array()
            .of(
                object().shape({
                    id: number().optional().nullable(),
                    from: object().required(
                        t('validations.messages.required', {
                            _field_: t('deliverytransformation.detail.fromAttribute'),
                        }),
                    ),
                    to: object().required(
                        t('validations.messages.required', { _field_: t('deliverytransformation.detail.toAttribute') }),
                    ),
                }),
            )
            .strict()
            .required(t('deliverytransformation.detail.attributeMappingsRequired'))
            .test('custom-validation', t('validations.messages.matchPrerequisites', { _field_: t('deliverytransformation.detail.attributeMapping')}), (value, ctx) => {
                return !value.some(x => (x.to as EntityAttributeDetailDto).dataType.id !== (x.from as EntityAttributeDetailDto).dataType.id)
            }) 
            .min(1, t('deliverytransformation.detail.attributeMappingsRequired')),
    });

    const defaultValidationSchema = object({
        transformationType: object().required(
            t('validations.messages.required', { _field_: t('deliverytransformation.detail.transformationType') }),
        ),
        fromEntity: object().required(
            t('validations.messages.required', { _field_: t('deliverytransformation.detail.fromEntity') }),
        ),
        toEntity: object().required(
            t('validations.messages.required', { _field_: t('deliverytransformation.detail.toEntity') }),
        ),
        parameter: string().nullable(),
        custom: mixed().test(
            'custom-validation',
            'The properties do not meet the validation criteria',
            function (_, context) {
                return customValidation(t, _, context);
            },
        ),
    });

    const transformationTypeRef = ref(detail?.transformationType);

    const validationSchema = computed(() => {
        const useAttributeMappingSchema = isAttributeMappingSupported(transformationTypeRef.value);
        return useAttributeMappingSchema ? attributeMappingValidationSchema : defaultValidationSchema;
    });

    return {
        validationSchema,
        transformationTypeRef,
    };
};

function customValidation(t: any, value: any, context: any) {
    const { fromEntity, toEntity, transformationType } = context.parent;

    if (!validateEntityMappingHasSameModel(transformationType, fromEntity, toEntity)) {
        return context.createError({
            message: t('deliverytransformation.detail.entityModelsNotEqual'),
            path: 'fromEntity',
        });
    }

    return true;
}

function validateEntityMappingHasSameModel(transformationType, fromEntity, toEntity) {
    if (fromEntity && toEntity && isEntityMappingType(transformationType)) {
        return hasSameEntityModel(fromEntity, toEntity);
    }
    return true;
}
