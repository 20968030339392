import {
    EntityAttributeDetailDto,
    ProductTransformationDetailDto,
    ProductTransformationLookupLinkDetailDto,
    SourceEntityDetailDto,
} from '@/api/portal-api/clients';
import { computed, ref } from 'vue';
import { object, string, array, number, mixed } from 'yup';
import {
    getLookupOptions,
    hasSameEntityModel,
    isAttributeMappingSupported,
    isEntityMappingType,
    isLookupLinkType,
} from './product-transformation-utils';
import { useProductTransformationLookupLinkStore } from '@/modules/configuration/store/product-transformation-lookup-link/product-transformation-lookup-link.store';

export const useProductTransformationValidations = (
    t,
    detail: ProductTransformationDetailDto = null,
    definitionId: number = null,
) => {
    const attributeMappingValidationSchema = object({
        transformationType: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.transformationType') }),
        ),
        fromEntity: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.fromEntity') }),
        ),
        toEntity: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.toEntity') }),
        ),
        parameter: string().nullable(),
        attributeMappings: array()
            .of(
                object().shape({
                    id: number().optional().nullable(),
                    from: object().required(
                        t('validations.messages.required', {
                            _field_: t('producttransformation.detail.fromAttribute'),
                        }),
                    ),
                    to: object().required(
                        t('validations.messages.required', { _field_: t('producttransformation.detail.toAttribute') }),
                    ),
                }),
            )
            .strict()
            .required(t('producttransformation.detail.attributeMappingsRequired'))
            .test('custom-validation', t('validations.messages.matchPrerequisites', { _field_: t('producttransformation.detail.attributeMapping')}), (value, ctx) => {
                return !value.some(x => (x.to as EntityAttributeDetailDto).dataType.id !== (x.from as EntityAttributeDetailDto).dataType.id)
            })    
            .min(1, t('producttransformation.detail.attributeMappingsRequired')),
    });

    const lookupLinkValidationSchema = object({
        transformationType: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.transformationType') }),
        ),
        fromEntity: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.fromEntity') }),
        ),
        toEntity: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.toEntity') }),
        ),
        parameter: string().nullable(),
        lookupLink: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.lookupLink') }),
        ),
        attributeMappings: array()
            .of(
                object().shape({
                    id: number().optional().nullable(),
                    from: object().required(
                        t('validations.messages.required', {
                            _field_: t('producttransformation.detail.fromAttribute'),
                        }),
                    ),
                    to: object().required(
                        t('validations.messages.required', { _field_: t('producttransformation.detail.toAttribute') }),
                    ),
                }),
            )
            .strict()
            .required(t('producttransformation.detail.attributeMappingsRequired'))
            .test('custom-validation', t('validations.messages.matchPrerequisites', { _field_: t('producttransformation.detail.attributeMapping')}), (value, ctx) => {
                return !value.some(x => (x.to as EntityAttributeDetailDto).dataType.id !== (x.from as EntityAttributeDetailDto).dataType.id)
            }) 
            .min(1, t('producttransformation.detail.attributeMappingsRequired')),
        custom: mixed().test(
            'custom-validation',
            'The properties do not meet the validation criteria',
            function (_, context) {
                return customValidation(t, _, context, definitionId);
            },
        ),
    });

    const defaultValidationSchema = object({
        transformationType: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.transformationType') }),
        ),
        fromEntity: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.fromEntity') }),
        ),
        toEntity: object().required(
            t('validations.messages.required', { _field_: t('producttransformation.detail.toEntity') }),
        ),
        parameter: string().nullable(),
        custom: mixed().test(
            'custom-validation',
            'The properties do not meet the validation criteria',
            function (_, context) {
                return customValidation(t, _, context);
            },
        ),
    });

    const transformationTypeRef = ref(detail?.transformationType);

    const validationSchema = computed(() => {
        if (isLookupLinkType(transformationTypeRef.value)) return lookupLinkValidationSchema;
        if (isAttributeMappingSupported(transformationTypeRef.value)) return attributeMappingValidationSchema;

        return defaultValidationSchema;
    });

    return {
        validationSchema,
        transformationTypeRef,
    };
};

function customValidation(t: any, value: any, context: any, definitionId: number = null) {
    const { fromEntity, toEntity, transformationType } = context.parent;

    if (!validateEntityMappingHasSameModel(transformationType, fromEntity, toEntity)) {
        return context.createError({
            message: t('producttransformation.detail.entityModelsNotEqual'),
            path: 'fromEntity',
        });
    }

    if (isLookupLinkType(transformationType)) {
        const lookupLinks = useProductTransformationLookupLinkStore().byDefinition(definitionId);

        if (!getLookupOptions(fromEntity, lookupLinks)?.length) {
            return context.createError({
                message: t('producttransformation.detail.noLookuplinkOnSourceEntity'),
                path: 'lookupLink',
            });
        }
    }

    return true;
}

function validateEntityMappingHasSameModel(transformationType, fromEntity, toEntity) {
    if (fromEntity && toEntity && isEntityMappingType(transformationType)) {
        return hasSameEntityModel(fromEntity, toEntity);
    }
    return true;
}
