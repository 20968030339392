import { createInstance, dateReviver } from '@/api';
import {
    SourceEntityCreateDto,
    SourceEntityClient,
    SourceEntityDetailDto,
    SourceEntityUpdateDto,
    DataConnectionDto,
    DataConnectionClient,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useProductProcessDefinitionEditStore } from '../product-process-definition/product-process-definition-edit.store';

export interface sourceentityStoreState {
    items?: { key: string; value: SourceEntityDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
}

export const client = new SourceEntityClient('', createInstance(), dateReviver);
export const dataConnectionClient = new DataConnectionClient('', createInstance(), dateReviver);

export const useSourceEntityStore = defineStore('source-entity-store', {
    state: (): sourceentityStoreState => ({
        items: [],
        loading: false,
        reloading: false,
    }),
    getters: {
        byDefinition: (state) => {
            return (definitionId: number): SourceEntityDetailDto[] => {
                const key = `definition-${definitionId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
        bySpecification: (state) => {
            return (specificationId: number): SourceEntityDetailDto[] => {
                const key = `specification-${specificationId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
    },
    actions: {
        async getByDefinition(defintionId: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);
            const definitionKey = `definition-${defintionId}`
            try {
                const response = await client.getByDelivery(defintionId);
                const items = this.items.filter((x) => x.key !== definitionKey);
                if (response?.length) {
                    this.items = [...items, { key: definitionKey, value: response }];
                } else {
                    this.items = [...items];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('sourceentity.detail.error.get.title'),
                    i18n.global.t('sourceentity.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async getBySpecification(specificationId: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);
            const key = `specification-${specificationId}`;

            try {
                const response = await client.getBySpecification(specificationId);
                const items = this.items.filter((x) => x.key !== key);
                if (response?.length) {
                    this.items = [...items, { key, value: response }];
                } else {
                    this.items = [...items];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('sourceentity.detail.error.get.title'),
                    i18n.global.t('sourceentity.detail.error.get.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(specificationId: number, dto: SourceEntityCreateDto) {
            this.reloading = true;
            try {
                const response = await client.create(dto);
                this.getBySpecification(specificationId, true);
                return response.id;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('sourceentity.detail.error.create.title'),
                    i18n.global.t('sourceentity.detail.error.create.content'),
                );
            } finally {
                this.reloading = false;
                useProductProcessDefinitionEditStore().closeCreate();
            }
        },
        async update(specificationId: number, entityId: number, dto: SourceEntityUpdateDto) {
            this.reloading = true;
            try {
                await client.update(entityId, dto);
                useAlertStore().setSuccess(
                    i18n.global.t('sourceentity.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                this.getBySpecification(specificationId, true);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('sourceentity.detail.error.update.title'),
                    i18n.global.t('sourceentity.detail.error.update.content'),
                );
            } finally {
                this.reloading = false;
                useProductProcessDefinitionEditStore().closeEdit();
            }
        },
        async remove(specificationId: number, id: number) {
            this.reloading = true;
            try {
                await client.delete(id);
                useAlertStore().setSuccess(
                    i18n.global.t('sourceentity.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                await this.getBySpecification(specificationId, true);
            } catch {
                useAlertStore().setError(
                    i18n.global.t('sourceentity.detail.error.delete.title'),
                    i18n.global.t('sourceentity.detail.error.delete.content'),
                );
            } finally {
                this.reloading = false;
            }
        },
        clear(specificationId: number) {
            const key = `specification-${specificationId}`;

            this.items = this.items.filter((x) => x.key !== key);
        },
    },
});
