<template>
  <form>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="transformationType"
        :label="t('deliverytransformation.detail.transformationType')"
        :placeholder="$t('deliverytransformation.detail.transformationType')"
        :options="transformationTypes"
        :custom-label="(e) => e.name"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="fromEntity"
        :label="t('deliverytransformation.detail.fromEntity')"
        :placeholder="$t('deliverytransformation.detail.fromEntity')"
        :options="fromEntityOptions"
        :custom-label="fromEntityLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsSelectField
        name="toEntity"
        :label="t('deliverytransformation.detail.toEntity')"
        :placeholder="$t('deliverytransformation.detail.toEntity')"
        :options="toEntityOptions"
        :custom-label="toEntityLabel"
      />
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <PbsInputField
        name="parameter"
        :label="t('deliverytransformation.detail.parameter')"
        :placeholder="t('deliverytransformation.detail.parameter')"
      />
    </vl-form-column>
    <vl-form-column v-if="canAddAttributeMapping" v-vl-spacer:bottom.small>
      <DeliveryTransformationAttributesEdit
        :definition-id="definitionId"
        :from-entity-id="fromEntityId"
        :to-entity-id="toEntityId"
      />
    </vl-form-column>
    <PbsErrorField name="custom-validation" />
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { useDeliveryTransformationStore } from '@/modules/configuration/store/delivery-transformation/delivery-transformation.store';
import { EnumerationDto, DeliveryTransformationDetailDto } from '@/api/portal-api/clients';
import { ENUM_TransformationTypes } from '@/modules/configuration/common';
import { useDeliveryEntityStore } from '@/modules/configuration/store/delivery-entity/delivery-entity.store';
import { useOperationEntityStore } from '@/modules/configuration/store/operation-entity/operation-entity.store';
import { DeliveryEntityDetailDto } from '@/api/portal-api/clients';
import { OperationEntityDetailDto } from '@/api/portal-api/clients';
import DeliveryTransformationAttributesEdit from '../delivery-transformation/DeliveryTransformationAttributesEdit.vue';
import { useDeliveryTransformationValidations } from './delivery-transformation-validations';
import { isAttributeMappingSupported } from './delivery-transformation-utils';
import PbsSelectField from '@/components/forms/PbsSelectField.vue';
import PbsInputField from '@/components/forms/PbsInputField.vue';
import PbsErrorField from '@/components/forms/PbsErrorField.vue';

const { t } = useI18n();

const store = useDeliveryTransformationStore();
const deliveryEntityStore = useDeliveryEntityStore();
const operationEntityStore = useOperationEntityStore();

const props = defineProps<{
  detail: DeliveryTransformationDetailDto;
  definitionId: number;
}>();

const { validationSchema, transformationTypeRef } = useDeliveryTransformationValidations(t, props.detail);

const { resetForm, setValues, handleSubmit, values } = useForm({
  validationSchema,
});

const isAttributeMappingType = computed(() => {
  return isAttributeMappingSupported(transformationType.value);
});

const transformationTypes = computed((): EnumerationDto[] => {
  const supportedTransformationTypes = [
    'Attribuut mapping',
    'Geometrie naar adres',
    'Adres naar adres',
    'Adres naar geometrie',
    'Voorbewerking',
  ];
  return ENUM_TransformationTypes.filter((x) => supportedTransformationTypes.includes(x.name));
});

const fromEntityOptions = computed(() => {
  return deliveryEntityStore.byDefinition(props.definitionId);
});

const toEntityOptions = computed(() => {
  return operationEntityStore.byDefinition(props.definitionId);
});

const fromEntityId = computed(() => {
  return values.fromEntity?.id ?? 0;
});

const toEntityId = computed(() => {
  return values.toEntity?.id ?? 0;
});

const transformationType = computed(() => {
  return values.transformationType;
});

const attributeMappings = computed(() => {
  return values.attributeMappings ?? [];
});

const canAddAttributeMapping = computed(() => {
  return fromEntityId.value && toEntityId.value && isAttributeMappingType.value;
});

const fromEntityLabel = (deliveryEntity: DeliveryEntityDetailDto) => {
  return `${deliveryEntity.code} (${deliveryEntity.label})`;
};

const toEntityLabel = (operationEntity: OperationEntityDetailDto) => {
  return `${operationEntity.code} (${operationEntity.label})`;
};

watch(fromEntityId, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  const fromEntity = fromEntityOptions.value.find((x) => x.id === newValue);
  if (fromEntity) {
    updateFromAttributeValues(fromEntity);
  }
});

watch(toEntityId, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  const toEntity = toEntityOptions.value.find((x) => x.id === newValue);
  if (toEntity) {
    updateToAttributeValues(toEntity);
  }
});

watch(transformationType, (newValue, oldValue) => {
  // update the transformation type reference for validation schema
  transformationTypeRef.value = newValue;

  if (newValue === oldValue) {
    return;
  }

  if (isAttributeMappingSupported(newValue)) {
    setValues({
      ...values,
      attributeMappings: getInitialAttributeMappings(),
    });
  }
});

onMounted(async () => {
  resetForm({
    values: getInitialFormValues(),
  });

  await Promise.all([
    deliveryEntityStore.getByDefinition(props.definitionId),
    operationEntityStore.getByDefinition(props.definitionId),
  ]);
});

const updateFromAttributeValues = (fromEntity: DeliveryEntityDetailDto) => {
  const fromAttributes = fromEntity.model?.attributes;
  if (!fromAttributes) {
    return;
  }

  let shouldUpdate = false;
  const updatedAttributeMappings = attributeMappings.value.map((x) => {
    const mapping = { ...x };
    const fromAttribute = fromAttributes.find((x) => x.id === mapping.from?.id);
    if (!fromAttribute) {
      mapping.from = null;
      shouldUpdate = true;
    }
    return mapping;
  });

  if (shouldUpdate) {
    setValues(
      {
        ...values,
        attributeMappings: updatedAttributeMappings,
      },
      true,
    );
  }
};

const updateToAttributeValues = (toEntity: OperationEntityDetailDto) => {
  const toAttributes = toEntity.model?.attributes;
  if (!toAttributes) {
    return;
  }

  let shouldUpdate = false;
  const updatedAttributeMappings = attributeMappings.value.map((x) => {
    const mapping = { ...x };
    const toAttribute = toAttributes.find((x) => x.id === mapping.to?.id);
    if (!toAttribute) {
      mapping.to = null;
      shouldUpdate = true;
    }
    return mapping;
  });

  if (shouldUpdate) {
    setValues({
      ...values,
      attributeMappings: updatedAttributeMappings,
    });
  }
};

const getInitialFormValues = () => {
  const values = {
    transformationType: props.detail.transformationType,
    fromEntity: props.detail.fromEntity,
    toEntity: props.detail.toEntity,
    parameter: props.detail.parameter,
    attributeMappings: getInitialAttributeMappings(),
  };

  return values;
};

const getInitialAttributeMappings = () => {
  return props.detail.attributeMappings
    .map((x) => ({
      id: x.id,
      from: x.fromAttribute,
      to: x.toAttribute,
    }))
    .sort((x, y) => (x.from.code > y.from.code ? 1 : -1));
};

const createAttributeMappingsToUpdate = () => {
  if (!isAttributeMappingType.value) {
    return [];
  }

  return values.attributeMappings.map((x) => ({
    id: x.id,
    fromAttributeId: x.from.id,
    toAttributeId: x.to.id,
  }));
};

const onSuccess = async (values) => {
  const attributeMappings = createAttributeMappingsToUpdate();

  const updateDto = {
    deliveryProcessDefinitionId: props.definitionId,
    transformationType: values.transformationType.name,
    fromEntityId: values.fromEntity.id,
    toEntityId: values.toEntity.id,
    parameter: values.parameter,
    attributeMappings,
  };

  await store.update(props.definitionId, props.detail.id, updateDto);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
