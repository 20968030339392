<template>
  <form>
    <vl-form-column v-vl-spacer:bottom.small>
      <vl-form-message-label for="transformationType">
        {{ $t('producttransformation.detail.transformationType') }}
      </vl-form-message-label>
      <Field v-slot="{ handleChange, value, errors }" name="transformationType">
        <vl-multiselect
          id="transformationType"
          :placeholder="$t('producttransformation.detail.transformationType')"
          :model-value="value"
          :v-model="value"
          :mod-error="!!errors.length"
          mod-block
          :options="transformationTypes"
          :mod-multiple="false"
          name="transformationType"
          type="search"
          :custom-label="(e) => e.name"
          @update:model-value="handleChange"
          @select="handleChange"
          @remove="handleChange"
        />
        <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
      </Field>
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <vl-form-message-label for="fromEntity">
        {{ $t('producttransformation.detail.fromEntity') }}
      </vl-form-message-label>
      <Field v-slot="{ handleChange, value, errors }" name="fromEntity">
        <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
        <vl-multiselect
          id="fromEntity"
          name="fromEntity"
          :placeholder="$t('producttransformation.detail.fromEntity')"
          :model-value="value"
          :v-model="value"
          :mod-error="!!errors.length"
          mod-block
          :options="fromEntityOptions"
          :mod-multiple="false"
          :allowEmpty="true"
          type="search"
          track-by="id"
          :custom-label="fromEntityLabel"
          @update:model-value="handleChange"
          @select="handleChange"
          @remove="handleChange(null)"
        />
      </Field>
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <vl-form-message-label for="toEntity">
        {{ $t('producttransformation.detail.toEntity') }}
      </vl-form-message-label>
      <Field v-slot="{ handleChange, value, errors }" name="toEntity">
        <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
        <vl-multiselect
          id="toEntity"
          name="toEntity"
          :placeholder="$t('producttransformation.detail.toEntity')"
          :model-value="value"
          :v-model="value"
          :mod-error="!!errors.length"
          mod-block
          :options="toEntityOptions"
          :mod-multiple="false"
          :allowEmpty="true"
          type="search"
          track-by="id"
          :custom-label="toEntityLabel"
          @update:model-value="handleChange"
          @select="handleChange"
          @remove="handleChange(null)"
        />
      </Field>
    </vl-form-column>
    <vl-form-column v-vl-spacer:bottom.small>
      <vl-form-message-label for="parameter">
        {{ $t('producttransformation.detail.parameter') }}
      </vl-form-message-label>
      <Field v-slot="{ handleChange, value, errors }" name="parameter">
        <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
        <vl-input-field
          id="parameter"
          name="parameter"
          :placeholder="$t('producttransformation.detail.parameter')"
          :modelValue="value"
          :mod-error="!!errors.length"
          mod-block
          @input="handleChange"
        ></vl-input-field>
      </Field>
    </vl-form-column>

    <vl-form-column v-if="isLookupType" v-vl-spacer:bottom.small>
      <vl-form-column v-vl-spacer:bottom.small>
        <vl-form-message-label for="lookupLink">
          {{ $t('producttransformation.detail.lookupLink') }}
        </vl-form-message-label>
        <Field v-slot="{ handleChange, value, errors }" name="lookupLink">
          <vl-form-message-error v-if="errors">{{ errors[0] }}</vl-form-message-error>
          <vl-multiselect
            id="lookupLink"
            name="lookupLink"
            :placeholder="$t('producttransformation.detail.lookupLink')"
            :model-value="value"
            :v-model="value"
            :mod-error="!!errors.length"
            mod-block
            :options="lookupLinkOptions"
            :mod-multiple="false"
            :allowEmpty="true"
            type="search"
            track-by="id"
            :custom-label="lookupLinkLabel"
            @update:model-value="handleChange"
            @select="handleChange"
            @remove="handleChange(null)"
          />
        </Field>
      </vl-form-column>
    </vl-form-column>
    <vl-form-column v-if="canAddAttributeMapping" v-vl-spacer:bottom.small>
      <ProductTransformationAttributesEdit
        :definition-id="definitionId"
        :from-entity-id="fromEntityId"
        :to-entity-id="toEntityId"
      />
    </vl-form-column>
    <Field v-slot="{ errors }" name="custom">
      <vl-form-message-error v-show="errors" v-vl-spacer:top.medium>{{ errors[0] }}</vl-form-message-error>
    </Field>
  </form>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch } from 'vue';
import { useForm, Field } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { useProductTransformationStore } from '@/modules/configuration/store/product-transformation/product-transformation.store';
import {
  EnumerationDto,
  ProductTransformationDetailDto,
  ProductTransformationLookupLinkDetailDto,
  ProductTransformationUpdateDto,
  TransformationTypeDto,
} from '@/api/portal-api/clients';
import { ENUM_TransformationTypes } from '@/modules/configuration/common';
import { useProductEntityStore } from '@/modules/configuration/store/product-entity/product-entity.store';
import { ProductEntityDetailDto } from '@/api/portal-api/clients';
import { SourceEntityDetailDto } from '@/api/portal-api/clients';
import ProductTransformationAttributesEdit from '../product-transformation/ProductTransformationAttributesEdit.vue';
import { useProductTransformationValidations } from './product-transformation-validations';
import { getLookupOptions, isAttributeMappingSupported, isLookupLinkType } from './product-transformation-utils';
import { useSourceEntityStore } from '@/modules/configuration/store/source-entity/source-entity.store';
import { useProductTransformationLookupLinkStore } from '@/modules/configuration/store/product-transformation-lookup-link/product-transformation-lookup-link.store';

const { t } = useI18n();

const store = useProductTransformationStore();
const productEntityStore = useProductEntityStore();
const sourceEntityStore = useSourceEntityStore();
const lookupLinkStore = useProductTransformationLookupLinkStore();
const props = defineProps<{
  detail: ProductTransformationDetailDto;
  definitionId: number;
}>();

const { validationSchema, transformationTypeRef } = useProductTransformationValidations(
  t,
  props.detail,
  props.definitionId,
);

const { resetForm, setValues, handleSubmit, values, setFieldValue } = useForm({
  validationSchema,
});

const isAttributeMappingType = computed(() => {
  return isAttributeMappingSupported(transformationType.value);
});

const isLookupType = computed(() => {
  return isLookupLinkType(transformationType.value);
});

const lookupLinkOptions = computed((): ProductTransformationLookupLinkDetailDto[] => {
  if (isLookupType.value) {
    const lookupLinks = lookupLinkStore.byDefinition(props.definitionId);

    return getLookupOptions(values.fromEntity, lookupLinks) ?? [];
  }

  return [];
});

const transformationTypes = computed((): EnumerationDto[] => {
  const supportedTransformationTypes = ['Attribuut mapping', 'Attribuut lookup', 'Nabewerking'];
  return ENUM_TransformationTypes.filter((x) => supportedTransformationTypes.includes(x.name));
});

const fromEntityOptions = computed((): SourceEntityDetailDto[] => {
  return sourceEntityStore.byDefinition(props.definitionId);
});

const toEntityOptions = computed((): ProductEntityDetailDto[] => {
  return productEntityStore.byDefinition(props.definitionId);
});

const fromEntityId = computed(() => {
  return values.fromEntity?.id ?? 0;
});

const toEntityId = computed(() => {
  return values.toEntity?.id ?? 0;
});

const transformationType = computed((): TransformationTypeDto => {
  return values.transformationType;
});

const attributeMappings = computed(() => {
  return values.attributeMappings ?? [];
});

const canAddAttributeMapping = computed(() => {
  return fromEntityId.value && toEntityId.value && isAttributeMappingType.value;
});

const fromEntityLabel = (productEntity: SourceEntityDetailDto) => {
  return `${productEntity.code} (${productEntity.label})`;
};

const toEntityLabel = (operationEntity: ProductEntityDetailDto) => {
  return `${operationEntity.code} (${operationEntity.label})`;
};

const lookupLinkLabel = (link: ProductTransformationLookupLinkDetailDto) => {
  return `${link.sourceEntity.label} (${link.keyAttribute.code} - ${link.valueAttribute.code})`;
};

watch(fromEntityId, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  const fromEntity = fromEntityOptions.value.find((x) => x.id === newValue);
  if (fromEntity) {
    updateFromAttributeValues(fromEntity);
    if (oldValue > 0) {
      setFieldValue('lookupLink', null, true);
    }
  }
});

watch(toEntityId, (newValue, oldValue) => {
  if (newValue === oldValue) {
    return;
  }

  const toEntity = toEntityOptions.value.find((x) => x.id === newValue);
  if (toEntity) {
    updateToAttributeValues(toEntity);
  }
});

watch(transformationType, (newValue, oldValue) => {
  // update the transformation type reference for validation schema
  transformationTypeRef.value = newValue;

  if (newValue === oldValue) {
    return;
  }

  if (isAttributeMappingSupported(newValue)) {
    setValues({
      ...values,
      attributeMappings: getInitialAttributeMappings(),
    });
  }
});

onMounted(async () => {
  await Promise.all([
    productEntityStore.getByDefinition(props.definitionId),
    sourceEntityStore.getByDefinition(props.definitionId),
    lookupLinkStore.getByDefinitionId(props.definitionId),
  ]);

  resetForm({
    values: getInitialFormValues(),
  });
});

const updateFromAttributeValues = (fromEntity: SourceEntityDetailDto) => {
  const fromAttributes = fromEntity.model?.attributes;
  if (!fromAttributes) {
    return;
  }

  let shouldUpdate = false;
  const updatedAttributeMappings = attributeMappings.value.map((x) => {
    const mapping = { ...x };
    const fromAttribute = fromAttributes.find((x) => x.id === mapping.from?.id);
    if (!fromAttribute) {
      mapping.from = null;
      shouldUpdate = true;
    }
    return mapping;
  });

  if (shouldUpdate) {
    setValues(
      {
        ...values,
        attributeMappings: updatedAttributeMappings,
      },
      true,
    );
  }
};

const updateToAttributeValues = (toEntity: ProductEntityDetailDto) => {
  const toAttributes = toEntity.model?.attributes;
  if (!toAttributes) {
    return;
  }

  let shouldUpdate = false;
  const updatedAttributeMappings = attributeMappings.value.map((x) => {
    const mapping = { ...x };
    const toAttribute = toAttributes.find((x) => x.id === mapping.to?.id);
    if (!toAttribute) {
      mapping.to = null;
      shouldUpdate = true;
    }
    return mapping;
  });

  if (shouldUpdate) {
    setValues({
      ...values,
      attributeMappings: updatedAttributeMappings,
    });
  }
};

const getInitialFormValues = () => {
  const values = {
    transformationType: props.detail.transformationType,
    fromEntity: props.detail.fromEntity,
    toEntity: props.detail.toEntity,
    parameter: props.detail.parameter,
    attributeMappings: getInitialAttributeMappings(),
    lookupLink: props.detail.lookupLink,
  };

  return values;
};

const getInitialAttributeMappings = () => {
  return props.detail.attributeMappings
    .map((x) => ({
      id: x.id,
      from: x.fromAttribute,
      to: x.toAttribute,
    }))
    .sort((x, y) => (x.from.code > y.from.code ? 1 : -1));
};

const createAttributeMappingsToUpdate = () => {
  if (!isAttributeMappingType.value) {
    return [];
  }

  return values.attributeMappings.map((x) => ({
    id: x.id,
    fromAttributeId: x.from.id,
    toAttributeId: x.to.id,
  }));
};

const onSuccess = async (values) => {
  const attributeMappings = createAttributeMappingsToUpdate();

  const updateDto: ProductTransformationUpdateDto = {
    productProcessDefinitionId: props.definitionId,
    transformationType: values.transformationType.name,
    fromEntityId: values.fromEntity.id,
    toEntityId: values.toEntity.id,
    parameter: values.parameter,
    attributeMappings,
    lookupLinkId: values?.lookupLink?.id,
  };

  await store.update(props.definitionId, props.detail.id, updateDto);
};

const onInvalidSubmit = ({ values, errors, results }) => {
  // console.log(values); // current form values
  // console.log(errors); // a map of field names and their first error message
  // console.log(results); // a detailed map of field names and their validation results
};

const onSubmit = handleSubmit(onSuccess, onInvalidSubmit);

defineExpose({ onSubmit });
</script>
