import { createInstance, dateReviver } from '@/api';
import {
    OperationSpecificationClient,
    OperationEntityDetailDto,
    UpdateOperationSpecificationDto,
    CreateOperationSpecificationDto,
} from '@/api/portal-api/clients';
import { i18n } from '@/i18n';
import { HorizontalPosition, VerticalPosition, useAlertStore } from '@/modules/core/store/alert.store';
import { defineStore } from 'pinia';
import { useDeliveryProcessDefinitionEditStore } from '../delivery-process-definition/delivery-process-definition-edit.store';
import { useOperationEntityStore } from '../operation-entity/operation-entity.store';

export interface OperationSpecificationStoreState {
    items?: { key: string; value: OperationEntityDetailDto[] }[];
    loading: boolean;
    reloading: boolean;
}

export const client = new OperationSpecificationClient('', createInstance(), dateReviver);

export const useOperationSpecificationStore = defineStore('operation-specification-store', {
    state: (): OperationSpecificationStoreState => ({
        items: [],
        loading: false,
        reloading: false,
    }),
    getters: {
        byDefinition: (state) => {
            return (definitionId: number): OperationEntityDetailDto[] => {
                const key = `definition-${definitionId}`;

                return state.items.find((x) => x.key === key)?.value ?? [];
            };
        },
    },
    actions: {
        async getByDefinition(definitionId: number, reloading: boolean = false) {
            reloading ? (this.reloading = true) : (this.loading = true);
            const key = `definition-${definitionId}`;

            try {
                const response = await client.getByDefinition(definitionId);
                const items = this.items.filter((x) => x.key !== key);
                if (response?.length) {
                    this.items = [...items, { key, value: response }];
                } else {
                    this.items = [...items];
                }
            } catch {
                useAlertStore().setError(
                    i18n.global.t('operationspecification.detail.error.title'),
                    i18n.global.t('operationspecification.detail.error.content'),
                );
            } finally {
                reloading ? (this.reloading = false) : (this.loading = false);
            }
        },
        async create(definitionId: number, dto: CreateOperationSpecificationDto) {
            this.reloading = true;
            try {
                const response = await client.create(dto);
                this.getByDefinition(definitionId, true);
                return response.id;
            } catch {
                useAlertStore().setError(
                    i18n.global.t('operationspecification.detail.create.error.title'),
                    i18n.global.t('operationspecification.detail.create.error.content'),
                );
            } finally {
                this.reloading = false;
                useDeliveryProcessDefinitionEditStore().closeCreate();
            }
        },
        async update(definitionId: number, id: number, dto: UpdateOperationSpecificationDto) {
            this.reloading = true;
            try {
                await client.update(id, dto);
                useAlertStore().setSuccess(
                    i18n.global.t('operationspecification.detail.success.update.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                this.getByDefinition(definitionId, true);
            } catch (error) {
                useAlertStore().setError(
                    i18n.global.t('operationspecification.detail.error.update.title'),
                    i18n.global.t('operationspecification.detail.error.update.content'),
                );
            } finally {
                this.reloading = false;
                useDeliveryProcessDefinitionEditStore().closeEdit();
            }
        },
        async remove(definitionId: number, id: number) {
            this.reloading = true;
            try {
                await client.delete(id);
                useAlertStore().setSuccess(
                    i18n.global.t('operationspecification.detail.success.delete.title'),
                    '',
                    VerticalPosition.Bottom,
                    HorizontalPosition.Right,
                );
                this.getByDefinition(definitionId, true);
            } catch {
                useAlertStore().setError(
                    i18n.global.t('operationspecification.detail.error.delete.title'),
                    i18n.global.t('operationspecification.detail.error.delete.content'),
                );
            } finally {
                this.reloading = false;
            }
        },
        clear(definitionId: number) {
            const key = `definition-${definitionId}`;

            const specification = this.items.find((x) => x.key === key)?.value;

            if (specification) {
                specification.forEach((s) => {
                    useOperationEntityStore().clear(s.id);
                });
            }

            this.items = this.items.filter((x) => x.key !== key);
        },
    },
});
